import React, { useState, createRef } from 'react';
import TxtFormError from '../atoms/TxtFormError';
import AdSenseSquare from '../AdSense/AdSenseSquare';
import './passcode.scss';

export default function Passcode(props) {
  const { title, value, changeAction, error = undefined } = props;
  const [otpValues, setOtpValues] = useState(Array(6).fill(''));

  const inputRefs = Array(6).fill().map(() => createRef());

  const [innerError, setInnerError] = useState('');

  const handleChange = (e, index) => {
    const { value } = e.target;
    const isInvalid = value && isNaN(parseInt(value));
    if (isInvalid) {
      // setInnerError('半角数字を入力してください');
      return;
    }
    // setInnerError('');
    if (value === '' || !isNaN(value)) {
      const newValues = [...otpValues];
      newValues[index] = value;
      setOtpValues(newValues);
      changeAction(newValues.join(''));
      // If input is not empty or deletion, focus the next input
      if (value && index < 5) {
        inputRefs[index + 1].current.focus();
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && !otpValues[index] && index > 0) {
      inputRefs[index - 1].current.focus();
    }
  };

  return (
    <>
      <div className="passcodeArea">
        <div className="passcodeWrap">
          <label>{title}</label>
          <div className="passcode">
            {otpValues.map((otpValue, index) => (
              <input
                key={index}
                value={otpValue}
                onChange={e => handleChange(e, index)}
                onKeyDown={e => handleKeyDown(e, index)}
                ref={inputRefs[index]}
                className="passcodeChild"
                type="text"
                autoFocus={index === 0}
                inputMode="numeric"
                maxLength="1"
                minLength="1"
                pattern="\d+"
                onInput={e => {
                  e.target.value = e.target.value.slice(0, 1)
                }}
              />
            ))}
          </div>
          {innerError
            ? <TxtFormError error={innerError} />
            : error && <TxtFormError error={error} />}
        </div>
        <AdSenseSquare />
      </div>
    </>
  );
}
