import { DataStore } from 'aws-amplify'
import moment from 'moment'
import { hashFunc } from '../modules/crypto'
import { User } from '../models'
import { getValidToken } from './token'
import axios from 'axios'
import { config } from '../config/config'

export const getUser = async (id) => {
  return await DataStore.query(User, id)
}

export const getRoomUsers = async (roomId) => {
  return await DataStore.query(
    User, c => c.roomID('eq', roomId)
  )
}

export const getUserWithPass = async (id, password) => {
  const hashedPassword = hashFunc(password)
  return await DataStore.query(
    User, c => c.id('eq', id).password('eq', hashedPassword)
  )
}

export const createUser = async(roomId) => {
  try {
    const ipInfo = await getIPInfo()

    // TODO 同じtokenで同じIPの場合、重複登録を回避すべきか
    return await DataStore.save(
      new User({
        ...ipInfo,
        roomID: roomId,
        nickname: '',
        userAgent: navigator.userAgent,
        hasPassword: false,
        pushNotification: true,
        securityNotification: false,
      })
    )
  } catch (error) {
    console.error('Failed to create user: ', error);
    return null;
  }
}

export const updateUserByFirstSetting = async (user, nickname, hasPassword, password, pushNotification) => {
  const hashedPassword = hasPassword && password !== "" ? hashFunc(password) : null;
  return await DataStore.save(
    User.copyOf(user, updatedUser => {
      updatedUser.nickname = nickname;
      updatedUser.hasPassword = hasPassword;
      updatedUser.password = hashedPassword;
      updatedUser.pushNotification = pushNotification;
      updatedUser.enterAt = moment().format();
    })
  );
}

export const updateUser = async (user, nickname, hasPassword, password, pushNotification, passwordUpdated = false) => {
  try {
    const freshUser = await DataStore.query(User, user.id);

    let finalPassword = password;
    if ((passwordUpdated || !freshUser.password) && hasPassword) {
      finalPassword = hashFunc(password);
    }

    await DataStore.save(
      User.copyOf(freshUser, updatedUser => {
        updatedUser.nickname = nickname;
        updatedUser.icon = user.icon;
        updatedUser.hasPassword = hasPassword;
        updatedUser.password = finalPassword;
        updatedUser.pushNotification = pushNotification;
      })
    );
    
    const updatedUser = await DataStore.query(User, freshUser.id);
    return updatedUser;
  } catch (error) {
    console.error('Error updating user:', error);
  }
}

/*****************************************************************/

export const createUserFromToken = async (token) => {
  const resToken = await getValidToken(token)

  if (resToken.length === 1) {
    const resUser = await createUser(resToken[0].roomID)
    if (resUser) return { result: true, id: resUser.id }
  }

  return { result: false }
}

export const getUserInfoList = async (roomId) => {
  return await getUserInfo(await getRoomUsers(roomId))
}

const getUserInfo = async (users) => {
  if (users.length === 0) return []

  const result = {}
  users.forEach(async (u) => {
    const iconUrl = await getIconUrl(u)
    
    result[u.id] = {
      nickname: u.nickname,
      iconUrl,
    }
  })

  return result
}

export const getIconUrl = async (user) => {
  const imagePath = `${user.roomID}/${user.id}/user_icon`
  return !user.icon
    ? ''
    : await axios.post(
        config.getPresignedUrlApi,
        { target: imagePath },
        { headers: { 'Content-Type': 'text/plain' }}
      ).then(response => {
        return response.status === 200 ? response.data : false
      })
}

const getIPInfo = async () => {
  const request = await fetch("https://ipinfo.io/json?token=51c8316432da29")
  if (!request) return {}

  const requestJson = await request.json()
  return {
      ip: requestJson.ip,
      hostname: requestJson.hostname,
      city: requestJson.city,
      region: requestJson.region,
      country: requestJson.country,
      loc: requestJson.loc,
      org: requestJson.org,
      postal: requestJson.postal,
      timezone: requestJson.timezone
    }
}
