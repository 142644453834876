import { openDB } from 'idb'
import { config } from '../config/config'
import { getLatestTalkSendAt } from '../datastores/talk'

export const getUpdatedTalkRoomList = async () => {
  const db = await openDB('db', 1, {
    upgrade(db) {
      // version1
      db.createObjectStore(config.indexedDBTable, {keyPath: 'id'})
    }
  })

  if (!db.objectStoreNames.contains('talkRoomList'))
    return []

  const transaction = db.transaction(config.indexedDBTable, 'readwrite')
  const objectStore = transaction.objectStore(config.indexedDBTable)

  let talkRoomList = await objectStore.getAll()

  // 各ルームの最新のトークを取得して更新
  for (let i = 0; i < talkRoomList.length; i++) {
    let latestTalkSendAt = await getLatestTalkSendAt(talkRoomList[i].roomId);

    // 最新のトークが存在しない場合は、適切な初期値を設定
    talkRoomList[i].latestTalk = latestTalkSendAt || '1970-01-01T00:00:00Z';
  }

  // 最新のトークでソート
  talkRoomList.sort((a, b) => new Date(b.latestTalk).getTime() - new Date(a.latestTalk).getTime());

  return talkRoomList
}

export const updateTalkRoomList = async (userId, roomId, pushNotification) => {
  const db = await openDB('db', 1, {
    upgrade(db) {
      // version1
      db.createObjectStore(config.indexedDBTable, {keyPath: 'id'})
    }
  })

  const transaction = db.transaction(config.indexedDBTable, 'readwrite')
  const objectStore = transaction.objectStore(config.indexedDBTable)

  objectStore.put({id: userId, roomId, pushNotification, created: new Date()})
}