import axios from 'axios'
import { DataStore, SortDirection } from 'aws-amplify'
import moment from 'moment'
import { config } from '../config/config'
import { formatDateToMD, formatTimeToHm } from '../modules/moment'
import { encryptFunc, decryptFunc } from '../modules/crypto'
import { Talk } from '../models'
import { getUserInfoList } from './user'
import { getRoom } from './room'

export const getRoomTalks = async (roomId) => {
  return await DataStore.query(
    Talk,
    c => c.roomID('eq', roomId)
      .sendAt('gt', moment().add(-3, 'd').format()),
    { sort: s => s.sendAt(SortDirection.ASCENDING) }
  )
}

export const getLatestRoomTalk = async (roomId) => {
  const result = await DataStore.query(
    Talk,
    c => c.roomID('eq', roomId)
      .sendAt('gt', moment().add(-3, 'd').format()),
    { sort: s => s.sendAt(SortDirection.DESCENDING), limit: 1 }
  )

  return result !== [] ? result[0] : result
}

export const getLatestTalkSendAt = async (roomId) => {
  const result = await DataStore.query(
    Talk,
    c => c.roomID('eq', roomId)
      .sendAt('gt', moment().add(-3, 'd').format()),
    { sort: s => s.sendAt(SortDirection.DESCENDING), limit: 1 }
  )

  return result.length > 0 ? result[0].sendAt : undefined
}

export const getLatestRoomOtherTalk = async (roomId, userId) => {
  const result = await DataStore.query(
    Talk,
    c => c.roomID('eq', roomId)
      .userID('ne', userId)
      .sendAt('gt', moment().add(-3, 'd').format()),
    { sort: s => s.sendAt(SortDirection.DESCENDING), limit: 1 }
  )

  return result.length > 0 ? result[0].id : undefined
}

export const createTalk = async (userId, roomId, contents, path, fileType) => {
  return await DataStore.save(
    new Talk({
      userID: userId,
      roomID: roomId,
      contents: encryptFunc(contents),
      path: path,
      fileType: fileType,
      sendAt: moment().format(),
    })
  )
}

/*****************************************************************/

export const getTalkRoomInfo = async (roomId, me) => {
  const roomUsers = await getUserInfoList(roomId)
  const room = await getRoom(roomId)

  return { room: room, roomUsers: roomUsers }
}

export const formatContents = async (talks, me, latestReadTalk) => {
  if (talks.length === 0) return []

  // TODO 3/13(木)の表示にする
  let initDate = formatDateToMD(talks[0].sendAt)
  let result = [{ type: 'date', date: initDate }]
  let latestUnRead = undefined
  let findLatestRead = false
  let allUnread = latestReadTalk === undefined

  for (let t of talks) {
    const type = t.userID === me ? 'self' : 'other'
    const date = formatDateToMD(t.sendAt)

    if (latestUnRead === undefined && (allUnread || findLatestRead) && type === 'other')
      latestUnRead = t.id

    if (initDate < date) {
      initDate = date
      result.push({ type: 'date', date: initDate })
    }

    if (t.path !== null) {
      const response = await axios.post(
        config.getPresignedUrlApi,
        { target: t.path },
        { headers: { 'Content-Type': 'text/plain' }}
      )

      t = { ...t, preSignedUrl: response.status === 200 ? response.data : false }
    }

    if (t.id === latestReadTalk) findLatestRead = true

    // t.contents = t.contents === '' ? t.contents : decryptFunc(t.contents)

    result.push({ ...t, showSendAt: formatTimeToHm(t.sendAt), type: type, contents: decryptFunc(t.contents) })
  }

  return { result: result, latestUnRead: latestUnRead }
}
