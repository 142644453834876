import React from 'react'
import './inputWhite.scss'
import TxtFormError from './TxtFormError'

export default function InputWhite(props) {
  const { 
    title=undefined, 
    type='text', 
    value='', 
    changeAction=() => {},
    error=undefined,
    onClick=undefined,
    className,
  } = props

  return (
    <>
      {title &&
        <label className={className}>{title}</label>
      }
      <input 
        className='inputWhite' 
        type={type} 
        value={value} 
        onChange={(e) => changeAction(e.target.value)}
        onClick={onClick}
      />
      {error &&
        <TxtFormError error={error} />
      }
    </>
  )
}
