import React from 'react'
import { Image } from '@aws-amplify/ui-react'
import { reformatContents, reformatFile } from '../../modules/talk'
import './otherTalk.scss'

export default function OtherTalk(props) {
  const { talkId, nickname, iconUrl, talk, file, fileType, presignedUrl, sendAt } = props

  const icon = iconUrl
    ? <Image src={iconUrl} width={28} height={28} objectFit="cover" borderRadius="50%" overflow="clip" />
    : <Image src='/images/icon_profile.svg' />

  if (talk)
    return (
      <div className="chatLeft" data-talk={talkId}>
        <div className="chatIcon">
          {icon}
        </div>
        <div className="chatItem">
          <p className="chatName">{nickname}</p>
          <div className="chatBox">
            {talk.split('\n').map((s, index) => <div key={index}>{reformatContents(s)}</div>)}
          </div>
        </div>
        <p className="chatSub">{sendAt}</p>
      </div>
    )

  if (file)
    return (
      <div className="chatLeft chatFile" data-talk={talkId}>
        <div className="chatIcon">
          {icon}
        </div>
        <div key={file}>{reformatFile(file, fileType, presignedUrl, nickname)}</div>
        <p className="chatSub">{sendAt}</p>
      </div>
    )
}
