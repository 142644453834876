import React, { useEffect, useState } from 'react'
import { getUpdatedTalkRoomList } from '../../modules/indexedDB'
import Loader from '../atoms/Loader'
import HeaderRoomList from '../organisms/HeaderRoomList'
import RoomListItem from '../molecules/RoomListItem'
import FooterMenu from '../organisms/FooterMenu'
import './roomList.scss'

export default function RoomList(props) {
  const [talkRoomList, setTalkRoomList] = useState([])
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const fetchUpdatedTalkRoomList = async () => {
      const result = await getUpdatedTalkRoomList();
      setTalkRoomList(result);
      setIsLoaded(true);
    };

    fetchUpdatedTalkRoomList();
  }, []);

  return (
    <>
      <HeaderRoomList />
      <div className="roomListArea">
        <div className="roomList">
          {!isLoaded ? <Loader /> : talkRoomList.map((r) => <RoomListItem key={`${r.roomId}/${r.id}`} userId={r.id} roomId={r.roomId} />)}
        </div>
      </div>
      <FooterMenu />
    </>
  )
}
