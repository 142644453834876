import React, { useEffect, useState } from 'react'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'
import { config } from './config/config'
import { routes } from './config/routing'
import '@aws-amplify/ui-react/styles.css';
import './App.css'
import NotFound from './components/pages/404'
import Top from './components/pages/Top'
import RoomList from './components/pages/RoomList'
import InviteRoom from './components/pages/InviteRoom'
import Room from './components/pages/Room'
import NewRoom from './components/pages/NewRoom'
import CreateRoom from './components/pages/CreateRoom'
import Rule from './components/pages/Rule'
import Privacy from './components/pages/Privacy'
import About from './components/pages/About'
import useHandleResize from './modules/useHandleResize'

function App() {

  const [vh, setVh] = useState(window.innerHeight);
  useHandleResize(setVh);

  return (
    <HelmetProvider>
      <GoogleReCaptchaProvider reCaptchaKey={config.reCaptchaKey}>
        <Router>
          <Wrapper>
            <div className="App" style={{
              height: `${vh}px`,
              paddingTop: `env(safe-area-inset-top)`,
              paddingBottom: `env(safe-area-inset-bottom)`
            }}>
              <Routes>
                <Route path='*' element={<NotFound />} />
                <Route path={routes.top} element={<Top />} />
                <Route path={routes.roomList} element={<RoomList />} />
                <Route path={routes.newRoom} element={<NewRoom />} />
                <Route path={`${routes.createRoom}/:roomId`} element={<CreateRoom />} />
                <Route path={`${routes.inviteRoom}/:token`} element={<InviteRoom />} />
                <Route path={`${routes.room}/:userId`} element={<Room />} />
                <Route path={routes.rule} element={<Rule />} />
                <Route path={routes.privacy} element={<Privacy/>} />
                <Route path={routes.about} element={<About/>} />
              </Routes>
            </div>
          </Wrapper>
        </Router>
      </GoogleReCaptchaProvider>
    </HelmetProvider>
  );
}

function Wrapper({ children }) {
  const navigate = useNavigate();
  useEffect(() => {
    navigator.serviceWorker.addEventListener('message', (event) => {
      if (event.data.action !== 'navigation-from-notificationclick') return;
      const destPath = event.data.path;
      const updatedRoomId = event.data.roomId;

      if (window.location.pathname === destPath) window.location.reload() //navigate(0) // reload
      else navigate(destPath);
    });
  }, []);

  return children;
}

export default App;
